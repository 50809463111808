import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import mermaid from 'mermaid';

function App() {
    const [code, setCode] = useState("graph TD;\nA-->B;\nA-->C;\nB-->D;\nC-->D;");
    const [error, setError] = useState("");
    const chartRef = useRef(null);

    useEffect(() => {
        // Initialize Mermaid with some options, including theme colors
        mermaid.initialize({
            startOnLoad: false,
            theme: "default", // You can change to 'dark' or other Mermaid themes
            themeVariables: {
                primaryColor: "#4CAF50",
                edgeLabelBackground: "#ffffff",
                secondaryColor: "#ffeb3b",
                tertiaryColor: "#03a9f4",
                nodeTextColor: "#000000",
            },
        });
    }, []);

    const generateChart = async () => {
        try {
            const response = await axios.post('/generate', { code });
            const chartData = response.data.chart;

            // Clear any previous chart
            if (chartRef.current) {
                chartRef.current.innerHTML = "";
            }

            // Generate the chart with Mermaid
            mermaid.render('mermaidChartDiv', chartData, (svgCode) => {
                if (chartRef.current) {
                    chartRef.current.innerHTML = svgCode;
                }
                setError("");  // Clear any previous error
            });
        } catch (error) {
            console.error('Error generating chart:', error);
            setError("Failed to generate the chart. Please check your Mermaid code or try again.");
        }
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', fontFamily: 'Arial, sans-serif' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '80%', padding: '20px' }}>
                <div style={{ flex: '1', marginRight: '20px' }}>
                    <textarea
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                        placeholder="Enter Mermaid code here"
                        style={{ width: '100%', height: '400px', fontSize: '16px', padding: '10px', border: '1px solid #ccc', borderRadius: '5px', backgroundColor: '#f0f0f0', color: '#333' }}
                    />
                </div>
                <div
                    id="mermaidChartDiv"
                    ref={chartRef}
                    style={{ flex: '1', height: '400px', border: '1px solid #ccc', padding: '10px', borderRadius: '5px', backgroundColor: '#f9f9f9', overflow: 'auto' }}
                />
            </div>
            {error && <div style={{ color: 'red', marginBottom: '20px' }}>{error}</div>}
            <button
                onClick={generateChart}
                style={{ padding: '10px 20px', fontSize: '16px', backgroundColor: '#4CAF50', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}
            >
                Generate Chart
            </button>
        </div>
    );
}

export default App;
